import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import RouteChangeListener from "./components/RouteChangeListener";
import AppToast from "./components/AppToast";
import {withRouter} from "react-router";
import IndexPage from "./pages/IndexPage";
import UploadPage from "./pages/UploadPage";
import ProcessingPage from "./pages/ProcessingPage";
import ResultPage from "./pages/ResultPage";
import inappsHelper from "./helpers/inapps.helper";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

class App extends React.Component {

  state = {
    isReady: window.clientConfig.isWeb,
  };

  componentDidMount() {
    window.webviewEventsListeners.setPrice.subscribe((data) => {
      this.context.setSkus(data || {});
    }, true);

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      inappsHelper.setUserClientData(window.clientConfig.webviewParams);
      inappsHelper.fetchList().then((res) => {
        this.context.setPurchases(res.data, () => {
          this.setState({isReady: true});
        });
      }).catch((err) => {
        console.error(err);
        this.setState({isReady: true});
      });

      webviewUtils.webviewOnPageFinished();
    }

    window.clientConfig.platform.os.toLowerCase() === "ios" && document.body.classList.add("ios");
    window.clientConfig.isWeb && document.body.classList.add("web");
    window.clientConfig.isWebview && document.body.classList.add("webview");
    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  handleWebviewTabSelected = () => {

  };

  handleRouteChanged = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <React.Fragment>
        <RouteChangeListener onChanged={this.handleRouteChanged} />
        <WindowSizeWatcher />
        <AppToast {...this.context.toast} />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          {this.state.isReady && <Switch>
            <Route exact path={routes.INDEX} render={(props) => <IndexPage {...props} />} />
            <Route exact path={routes.UPLOAD} render={(props) => <UploadPage {...props} />} />
            <Route exact path={routes.PROCESSING} render={(props) => <ProcessingPage {...props} />} />
            <Route exact path={routes.RESULT} render={(props) => <ResultPage {...props} />} />
          </Switch>}
          <ModalWrapper />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContextProvider>
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  </AppContextProvider>
);