/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";
import {sort} from "css-loader/dist/utils";

function getGroups() {
  return [
    groups.g1,
  ];
}

const positionsTemplatesPairs = [
  {canvasId: 8206, maskId: 8207},
];

const promptTemplates = {
  male: [
    {id: 8446, weight: 3.205908158},
    {id: 8296, weight: 3.177533246},
    {id: 8373, weight: 2.696049297},
    {id: 8342, weight: 2.599552605},
    {id: 8298, weight: 2.442255047},
    {id: 8297, weight: 2.36529271},
    {id: 8447, weight: 2.356678921},
    {id: 8408, weight: 2.353534043},
    {id: 8302, weight: 2.33692899},
    {id: 8340, weight: 2.311549107},
    {id: 8300, weight: 2.240555703},
    {id: 8382, weight: 2.197633725},
    {id: 8378, weight: 2.107573486},
    {id: 8412, weight: 2.103207769},
    {id: 8522, weight: 2.102873118},
    {id: 8289, weight: 1.977010984},
    {id: 8455, weight: 1.932505628},
    {id: 8316, weight: 1.922460189},
    {id: 8335, weight: 1.911029223},
    {id: 8317, weight: 1.868470446},
    {id: 8519, weight: 1.849028448},
    {id: 8384, weight: 1.847701225},
    {id: 8429, weight: 1.833959574},
    {id: 8418, weight: 1.809006893},
    {id: 8303, weight: 1.743125821},
    {id: 8314, weight: 1.725597304},
    {id: 8301, weight: 1.655011209},
    {id: 8357, weight: 1.623304728},
    {id: 8518, weight: 1.604717573},
    {id: 8361, weight: 1.59014026},
    {id: 8385, weight: 1.587282887},
    {id: 8321, weight: 1.576552191},
    {id: 8364, weight: 1.566991715},
    {id: 8353, weight: 1.519819051},
    {id: 8319, weight: 1.43311556},
    {id: 8332, weight: 1.373750714},
    {id: 8507, weight: 1.373452591},
    {id: 8299, weight: 1.36678405},
    {id: 8333, weight: 1.363652943},
    {id: 8448, weight: 1.314448177},
    {id: 8350, weight: 1.294946595},
    {id: 8400, weight: 1.269374964},
    {id: 8427, weight: 1.256263357},
    {id: 8360, weight: 1.208850532},
    {id: 8426, weight: 1.177340839},
    {id: 8440, weight: 1.151054011},
    {id: 8439, weight: 1.136313309},
    {id: 8449, weight: 1.114078323},
    {id: 8311, weight: 1.110223141},
    {id: 8381, weight: 1.034480968},
    {id: 8280, weight: 1.021727093},
    {id: 8414, weight: 1.011766696},
    {id: 8436, weight: 1.011134258},
    {id: 8380, weight: 0.9723610698},
    {id: 8407, weight: 0.9611036334},
    {id: 8516, weight: 0.9562962942},
    {id: 8284, weight: 0.9463406359},
    {id: 8510, weight: 0.885337294},
    {id: 8453, weight: 0.8580140648},
    {id: 8454, weight: 0.8518617949},
    {id: 8351, weight: 0.8050858185},

  ].map((item) => {
    item.weight = Math.floor(item.weight ** 3);
    return item;
  }),
  female: [
    {id: 8296, weight: 3.517517902},
    {id: 8297, weight: 2.91828479},
    {id: 8412, weight: 2.898363468},
    {id: 8298, weight: 2.849509464},
    {id: 8342, weight: 2.665028791},
    {id: 8373, weight: 2.629930391},
    {id: 8335, weight: 2.614362264},
    {id: 8340, weight: 2.536026441},
    {id: 8302, weight: 2.517142534},
    {id: 8522, weight: 2.423426299},
    {id: 8446, weight: 2.302689411},
    {id: 8382, weight: 2.244170785},
    {id: 8418, weight: 2.19945266},
    {id: 8314, weight: 2.101296046},
    {id: 8303, weight: 2.047262793},
    {id: 8385, weight: 1.97058602},
    {id: 8518, weight: 1.966058216},
    {id: 8332, weight: 1.952755992},
    {id: 8364, weight: 1.916043007},
    {id: 8384, weight: 1.88133885},
    {id: 8447, weight: 1.811961515},
    {id: 8361, weight: 1.810257818},
    {id: 8408, weight: 1.79259871},
    {id: 8455, weight: 1.736464122},
    {id: 8333, weight: 1.706036075},
    {id: 8381, weight: 1.611055595},
    {id: 8357, weight: 1.543812759},
    {id: 8400, weight: 1.491898151},
    {id: 8353, weight: 1.469176602},
    {id: 8360, weight: 1.44829281},
    {id: 8519, weight: 1.443894795},
    {id: 8507, weight: 1.428949489},
    {id: 8380, weight: 1.422880548},
    {id: 8311, weight: 1.372831289},
    {id: 8280, weight: 1.332892799},
    {id: 8448, weight: 1.328969769},
    {id: 8449, weight: 1.318411215},
    {id: 8321, weight: 1.307735945},
    {id: 8350, weight: 1.303425041},
    {id: 8429, weight: 1.283947637},
    {id: 8289, weight: 1.281197364},
    {id: 8427, weight: 1.230666167},
    {id: 8510, weight: 1.22796398},
    {id: 8284, weight: 1.211606734},
    {id: 8317, weight: 1.200863454},
    {id: 8316, weight: 1.165323606},
    {id: 8378, weight: 1.142317212},
    {id: 8436, weight: 1.117615335},
    {id: 8351, weight: 1.088970057},
    {id: 8319, weight: 1.086556348},
    {id: 8440, weight: 1.086364003},
    {id: 8426, weight: 1.066214093},
    {id: 8407, weight: 1.052866255},
    {id: 8301, weight: 1.003419975},
    {id: 8439, weight: 0.9084299824},
    {id: 8454, weight: 0.8961436143},
    {id: 8299, weight: 0.8774609296},
    {id: 8516, weight: 0.8709916637},
    {id: 8453, weight: 0.8412823844},
    {id: 8300, weight: 0.8387677734},
    {id: 8414, weight: 0.6882402615},
  ].map((item) => {
    item.weight = Math.floor(item.weight ** 3);
    return item;
  }),
};

function getRandomIndexWithWeight(array) {
  if (array.isEmpty()) {
    return -1;
  }

  const sum = array.reduce((t, item) => t + item.weight, 0);
  let r = Math.floor(Math.random() * (sum + 1));

  return array.findIndex((item) => {
    r -= item.weight;
    return r <= 0;
  });
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(gender) {
    const sortedItems = [];
    const templates = promptTemplates[gender].slice();

    while (templates.isNotEmpty()) {
      const index = getRandomIndexWithWeight(templates);
      const promptItem = templates.splice(index, 1).first();

      sortedItems.push({
        promptId: promptItem.id,
        faceId: 7092,
        canvasId: positionsTemplatesPairs[0].canvasId,
        maskId: positionsTemplatesPairs[0].maskId,
      });
    }

    return sortedItems.map((item) => {
      const combo = new CreativeConfig(
        groups.g1,
        `${item.faceId}_[${item.canvasId}_${item.maskId}]_${item.promptId}`,
        handlersNames.COMBO
      );

      combo.setExtra("t_prompt_id", item.promptId);
      combo.setExtra("t_face_id", item.faceId);
      combo.setExtra("t_canvas_id", item.canvasId);
      combo.setExtra("t_mask_id", item.maskId);
      combo.setExtra("t_gender", item.gender);

      combo.setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: item.faceId, images: [{src: "@0"}]},
        {
          id: item.promptId,
          templateParams: {
            gender: gender === "male" ? "man" : "woman",
            canvas_template_name: item.canvasId,
            mask_template_name: item.maskId,
          },
          setAsFile: "raw",
        },
      ]);

      return combo;
    });
  },
};
